import { Box } from "@mui/material";

import Login from "../components/Login";

const LoginPage = () => {
  return (
    <>
      <Box>
        <Login />
      </Box>
    </>
  );
};

export default LoginPage;
